import React from "react";
import { TelegramIcon } from "../atoms/Icons";
import Layout from "../components/layout";

const Contact = () => {
  return (
    <Layout>
      <div className="flex flex-col lg:flex-row lg:items-center justify-between lg:py-4 ">
        <div className="my-auto lg:hidden">
          <h1 className="mobile-header lg:desktop-header">Contact Us</h1>
          <p className="mobile-body lg:desktop-body">
            Don’t be shy. We would love to hear from you!
          </p>
        </div>
        <div className="my-auto hidden lg:block">
          <h1 className="mobile-header lg:desktop-header lg:block">Say Hi!</h1>
          <p className="mobile-body lg:desktop-body">
            Don’t be shy. We would love to
            <br /> hear from you!
          </p>
        </div>
        <form
          className="my-auto py-2 flex flex-col gap-4 lg:gap-6 lg:max-w-3xl w-full mobile-body lg:desktop-body"
          method="post"
          netlify-honeypot="bot-field"
          data-netlify="true"
          name="contact"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <div className="flex flex-col">
            <label className="py-2" htmlFor="name">
              Full Name*
            </label>
            <input
              className="input"
              type="text"
              name="name"
              id="name"
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="py-2" htmlFor="email">
              E-mail*
            </label>
            <input
              className="input"
              type="email"
              name="email"
              id="email"
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="py-2" htmlFor="subject">
              Subject
            </label>
            <input
              className="input"
              id="subject"
              type="subject"
              name="subject"
            />
          </div>
          <div className="flex flex-col">
            <div className="flex justify-between items-center">
              <label className="py-2" htmlFor="message">
                Message*
              </label>
              <p className="ml-auto text-gray-400" id="message">
                Max. 500 characters
              </p>
            </div>

            <textarea
              rows="5"
              className="input mb-6"
              type="message"
              name="message"
              maxlength="500"
              required
            />

            <button
              type="submit"
              className="button-contact flex items-center justify-center lg:ml-auto px-12 py-2 md:ml-auto"
            >
              <TelegramIcon /> Send
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Contact;
